<template>
  <div class="content">
    <div class="top">
      <div class="input_item">
        <span>店鋪</span>
        <el-select
          size="small"
          filterable
          v-model="shop_id"
          placeholder="請選擇店鋪"
          clearable
        >
          <el-option
            v-for="item in shopList"
            :key="item.value"
            :label="item.shop_name"
            :value="item.shop_id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="input_item">
        <span>日期</span>
        <el-date-picker
          size="small"
          v-model="goodsDate"
          type="daterange"
          range-separator="-"
          start-placeholder="選擇開始日期"
          end-placeholder="選擇結束日期"
          clearable
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div>
        <span>商品名稱</span>
        <el-input
          style="width: 70%; margin-left: 10px"
          size="small"
          placeholder="请输入商品名称"
          v-model="keyword"
          clearable
        >
        </el-input>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="getGoodsSellTotalList()"
        clearable
        >搜索
      </el-button>
      <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetGoodsList"
        >重置</el-button
      > -->
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table
        :data="goodsList"
        border
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="goods_name" label="商品名称"> </el-table-column>
        <el-table-column prop="goods_no" label="商品條碼"> </el-table-column>
        <el-table-column prop="total_num" label="總销量"> </el-table-column>
        <el-table-column prop="total_price" label="總销售额"> </el-table-column>
        <el-table-column prop="profit" label="總利润"> </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { listShop } from "@/api/shop";
import { getGoodsSellTotal, getShopSellTotal } from "@/api/goodsSellTotal";
export default {
  name: "goodsSellTotal",
  data() {
    return {
      shopList: [],
      shop_id: null,
      goodsDate: [],
      goodsList: [],
      pageSize: 50,
      total: null,
      page: 1,
      keyword: "",
    };
  },
  created() {
    this.formatDate();
    this.getAllListShop();
    this.getGoodsSellTotalList();
  },
  watch: {
    shop_id() {
      this.getGoodsSellTotalList();
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGoodsSellTotalList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsSellTotalList();
    },
    // 获取所有店铺
    async getAllListShop() {
      try {
        const { data } = await listShop();
        this.shopList = data.list;
        this.shop_id = this.shopList[0].shop_id
      } catch (e) {
        console.log(e);
      }
    },
    // 获取商品
    async getGoodsSellTotalList() {
      const shopInfo = {
        shop_id: this.shop_id,
        page: this.page,
        pageSize: this.pageSize,
        start_time: this.goodsDate[0],
        end_time: this.goodsDate[1],
        keyword: this.keyword,
      };
      try {
        const { data } = await getGoodsSellTotal(shopInfo);
        this.goodsList = data.list;
        this.total = data.count;
      } catch (e) {
        console.log(e);
      }
    },
    // 格式化初始日期为最近7天
    formatDate() {
      const end_time = dayjs(new Date()).format("YYYY-MM-DD");
      const start_time = dayjs(
        new Date(new Date().getTime() - 168 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD");

      this.goodsDate = [start_time, end_time];
    },
    // 重置列表
    resetGoodsList() {
      this.shop_id = "";
      this.page = 1;
      this.pageSize = 10;
      this.start_time = "2023-03-04";
      this.end_time = "2023-03-20";
      this.keyword = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px;
  height: 400px;
  .top {
    display: flex;
    width: 100%;
    // height: 100px;
    padding: 10px;
    background-color: #fff;
    .input_item {
      margin-right: 20px;
      ::v-deep .el-input__inner {
        width: 220px;
      }
      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .table {
    width: 100%;
    // padding: 10px;
    background-color: #fff;
    margin-top: 10px;
  }
}
</style>
